:root {
  /*Body background color*/
  --main-background-color: #0f0f0f;

  /*General conatiner and modal background colors*/
  --main-container-bg-color: #fff;
  --secondary-container-bg-color: #f9f9f9;
  --primary-container-border-color: #e9e9e9;
  --modal-background-color: #fff;
  --modal-font-color: #000;
  --modal-footer-bg-color: #fff;

  /*Logo Color*/
  --logo-icon-color: #2ce983;

  /*Button colors*/
  --primary-button-color: #14bf59;
  --primary-button-active-color: #0f994c;
  --primary-button-hover-color: #1ac363;
  --primary-button-font-color: #fff;
  --secondary-button-color: #f2f2f2;
  --secondary-button-font-color: #000;
  --secondary-button-hover-color: #dcdcdc;
  --secondary-button-active-color: #cccccc;

  /*Navbar menu colors*/
  --dropdown-item-hover: #e4e4e4;
  --dropdown-item-active: #cbcbcb;
  --dropdown-menu-background: #fff;
  --top-menu-background: #fff;
  --top-search-input-color: #fff;

  /* Font colors */
  --primary-font-color: #000;
  --secondary-font-color: #747474;

  /*Table colors*/
  --table-row-hover: #dcdcdc;
  --table-header-background: #f9f9f9;
  --pagination-active-color: #14bf59;
  --row-table-bg-color: #fff;

  /*text inputs colors*/
  --text-input-bg-color: #0f0f0f;
  --text-input-border-color: #e9e9e9;
  --input-background-color: #fff;
}

/* Global css variables for the guest-portal */
#guest-portal {
  /*Body background color*/
  --main-background-color: #0f0f0f;

  /*General container and modal background colors*/
  --main-container-bg-color: #232323;
  --modal-background-color: #232323;
  --modal-footer-bg-color: #0f0f0f;

  /*Logo Color*/
  --logo-icon-color: #2ce983;

  /*Button colors*/
  --primary-button-color: #2ce983;
  --primary-button-active-color: #3abb76;
  --primary-button-hover-color: #65f3a7;
  --primary-button-font-color: #fff;
  --secondary-button-color: #232323;
  --secondary-button-font-color: #afafaf;
  --secondary-button-hover-color: #2b2929;
  --secondary-button-active-color: #000000;
  --primary-button-border-color: #232323;

  /*Navbar menu colors*/
  --top-menu-background: #232323;
  --top-search-input-color: #0f0f0f;

  /* Font colors */
  --primary-font-color: #fff;
  --secondary-font-color: #afafaf;

  /*Table colors*/
  --table-row-hover: #424346;
  --table-header-background: #0f0f0f;
  --pagination-active-color: #2ce983;
  --row-table-bg-color: #232323;

  --article-card-bg-color: #232323;

  /*text inputs colors*/
  --text-input-bg-color: #0f0f0f;
  --text-input-border-color: #0f0f0f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background-color) !important;
  /* color: #fff !important; */
}

h2 {
  color: var(--primary-font-color);
}

h5 {
  color: var(--primary-font-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-control {
  background-color: var(--input-background-color);
  border: 1px solid var(--text-input-border-color);
  color: var(--primary-font-color);
}

.form-control:disabled {
  background-color: #d1d5db !important;
  opacity: 1 !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  border: 1px solid #d1d5db;
  background: #fff;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  border-radius: 6px;
  padding: 9px 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #374151;
  opacity: 1;
}

.save-button {
  background: #2ce983 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  border: none !important;
  padding: 9px 17px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--primary-button-font-color) !important;
  font-family: "Inter" !important;
  min-width: 110px;
}

.save-button:hover {
  background-color: #1ac363 !important;
}

.save-button:active {
  background-color: #0f994c !important;
}

.cancel-button {
  background: #f2f2f2 !important;
  border: 1px solid #f2f2f2 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  padding: 9px 17px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #000000 !important;
  line-height: 22px !important;
  min-width: 110px;
}

.cancel-button:hover {
  background-color: #dcdcdc !important;
}

.cancel-button:active {
  background-color: #cccccc !important;
}

.okButton {
  background-color: var(--primary-button-color) !important;
  color: var(--primary-button-font-color) !important;
}

.table-add-button {
  padding: 9px 17px !important;
  background: var(--primary-button-color) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
}

.table-add-button:hover {
  background-color: var(--primary-button-hover-color) !important;
}

.table-add-button:active {
  background-color: var(--primary-button-active-color) !important;
}

.table-add-button img {
  margin-right: 12px !important ;
  margin-left: 0px !important ;
  vertical-align: baseline !important ;
  width: 8px !important;
  height: 8px !important;
}

.checkbox:checked {
  background-color: var(--primary-button-font-color);
  box-shadow: inset 0px 0px 0px 5px var(--primary-button-color);
  transition: 0.5s;
  border: none;
}

.checkbox {
  position: relative;
  margin-left: 4px;
  width: 18px;
  height: 18px;
  float: left;
  margin: 2px 4px 2px 1px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #d1d5db;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  transition: 0.5s;
}

.refresh-button {
  background: var(--primary-button-font-color) !important;
  border: 1px solid #d1d5db !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 10px 12px !important;
  color: #374151 !important;
}

.logo-icon {
  fill: var(--logo-icon-color);
}

/*Margins*/
.m-l-24 {
  margin-left: 24px;
}

.mt-8px {
  margin-top: 8px !important;
}

.mr-8px {
  margin-right: 8px !important;
}

.ml-8px {
  margin-left: 8px !important;
}

.mb-8px {
  margin-bottom: 8px !important;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.mb-24px {
  margin-bottom: 5px !important;
}

/*Paddings*/
.pt-16px {
  padding-top: 16px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.p-8px {
  padding: 8px !important;
}

.container-fluid,
.container {
  font-family: "Inter";
}
