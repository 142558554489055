.export-option-container {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
}

.export-option {
  position: relative;
  display: inline-block;
  padding: 9px 22px;
  border-radius: 17.5px;
  border: 1px solid #e9e9e9;
  background-color: #f9f9f9;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 10px;
  cursor: pointer;
}

.export-option.selected {
  background-color: #EBF8ED;
  color: #0CA925;
  border: 1px solid #14BF59;
}

.export-option:hover {
  background-color: #EBF8ED;
  color: #0CA925;
  border: 1px solid #14BF59;
}

.export-option img {
  width: 80px;
  padding-top: 15px;
  padding-bottom: 15px;
}
